ul{
    margin: 0;
    padding: 0;
}
ul li{
    list-style: none;
    padding: 0 !important;
    border-bottom: 1px solid #ccc;
    margin-top: 30px;
}
ul li a:hover{
    cursor: pointer;
    background: #4f5050;
    color: #ffffff !important;
    margin: 0 !important;
}