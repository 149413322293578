body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul{
    margin: 0;
    padding: 0;
}
ul li{
    list-style: none;
    padding: 0 !important;
    border-bottom: 1px solid #ccc;
    margin-top: 30px;
}
ul li a:hover{
    cursor: pointer;
    background: #4f5050;
    color: #ffffff !important;
    margin: 0 !important;
}
